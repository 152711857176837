<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar dense flat color="grey lighten-2" class="ma-0 pa-0">
      <div class="text-caption text-lg-left blue-grey--text">
        <v-icon small color="primary" class="mb-1">mdi-information</v-icon>
        <span class="text-body-2 primary--text ml-1">자동완성키워드는 모든 조직원과 공유됩니다.</span>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn text icon>
        <v-icon small>apps</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
    >
      <v-row
        align="center"
        justify="center"
        no-gutters
        class="ma-0 pa-0"
      >
        <v-col cols="12">

          <!-- content area -->
          <v-row
            no-gutters
            justify="space-between"
            class="ma-0 pa-0"
          >
            <!-- 좌측 내용(상위 카테고리) 시작 -->
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '4' : '2'"
            >
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0 overflow-y-auto"
                max-width="300"
                min-width="120"
              >
                <!-- <v-list dense shaped>
                  <template v-for="item in kwFields">
                    <v-list-item
                      :key="item.title"
                      :to="item.to"
                      color="primary"
                      @click.stop="selectKeyword(item.field)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.caption"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list> -->
                <v-list dense shaped>
                  <!-- <v-subheader>적용 항목</v-subheader> -->
                  <v-list-item-group v-model="selectedField" color="primary">
                    <v-list-item
                      v-for="(item, i) in kwFields"
                      :key="i"
                      @click.stop="selectKeyword(item.field)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.caption"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <!-- 가운데 -->
            <v-divider vertical class="mx-1"></v-divider>

            <!-- 우측 -->
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '7' : ''"
            >
              <v-row
                no-gutters
                class="ma-0 pa-0"
              >
                <v-col cols="12">
                  <v-toolbar
                    dense
                    flat
                    class="pa-0 ma-0 overflow-x-auto"
                  >
                    <v-btn
                      :disabled="orderChanged || isSomeChecked"
                      text
                      color="deep-purple darken-3"
                      @click="addKeyword"
                      class="pa-1 mr-2"
                    >
                      <v-icon
                        small
                        left
                        class="mr-0"
                      >mdi-plus-box-outline</v-icon>등록
                    </v-btn>
                    <v-btn
                      :disabled="!orderChanged"
                      text
                      color="primary"
                      @click="orderChange"
                      class="pa-1 mr-2"
                    >
                      <v-icon
                        small
                        left
                        class="mr-0"
                      >mdi-cached</v-icon>순서변경
                    </v-btn>
                    <v-btn
                      :disabled="selectedKeywords.length <= 0"
                      text
                      :color="allChecked ? 'warning' : 'success'"
                      @click="allCheck"
                      class="pa-1 mr-2"
                    >
                      <v-icon
                        small
                        left
                        class="mr-0"
                      >
                        {{ allChecked ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
                      </v-icon>
                      {{ allChecked ? '전체해제' : '전체선택' }}
                    </v-btn>
                    <v-btn
                      :disabled="!isSomeChecked"
                      text
                      color="error"
                      @click="rmKeywords"
                      class="pa-1"
                    >
                      <v-icon
                        small
                        left
                        class="mr-0"
                      >mdi-trash-can-outline</v-icon> 선택삭제
                    </v-btn>
                    <v-progress-linear
                      :active="keywordLoading"
                      :indeterminate="keywordLoading"
                      absolute
                      bottom
                      color="light-blue"
                    ></v-progress-linear>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-divider></v-divider>

                  <div class="ma-3"></div>

                  <draggable
                    v-if="selectedKeywords.length > 0"
                    :list="selectedKeywords"
                    :disabled="!dndEnabled"
                    @start="dragging = true"
                    @end="dragging = false"
                    @update="orderChanged = true"
                  >
                    <v-row
                      v-for="(keyword, i) in selectedKeywords"
                      :key="i"
                    >
                      <v-hover v-slot:default="{ hover }" transition="fade-transition">
                        <v-col cols="6" class="mx-1 my-2 px-1 py-0">
                          <v-chip
                            :color="(hover || keyword.isChecked) ? 'light-blue lighten-5' : 'white'"
                            class="ml-1"
                          >
                            <v-icon
                              small
                              left
                              class="mr-2"
                              @click="keyword.isChecked = !keyword.isChecked"
                            >
                              {{ keyword.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                            </v-icon>
                            {{ keyword.text }}
                            <!-- <v-icon
                              v-show="hover"
                              small
                              right
                              :data-index="i"
                              :data-edit="keyword.text"
                              @click="addKeyword"
                              class="ml-5"
                            >mdi-pencil</v-icon> -->
                            <v-icon
                              v-show="hover"
                              small
                              right
                              color="error"
                              @click="rmOneKeyword(i)"
                              class="ml-5"
                            >mdi-trash-can-outline</v-icon>
                          </v-chip>
                        </v-col>
                      </v-hover>
                    </v-row>
                  </draggable>
                  <v-row v-else>
                    <v-col cols="6" class="ma-1 pa-3">
                      <v-alert
                        dense
                        border="left"
                        type="warning"
                      >
                        등록된 키워드가 없습니다.
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--// content area -->

        </v-col>
      </v-row>
    </v-card>

    <!-- !! 키워드 등록/수정 팝업 -->
    <add-keyword-pop ref="addKeywordPop"></add-keyword-pop>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import addKeywordPop from '@/components/admin/addKeywordPop' // 키워드 등록/수정 팝업
import sleep from '@/lib/sleep'

export default {
  components: {
    draggable,
    addKeywordPop
  },

  data: () => ({
    // 중요:[2021.4.8 수정] 이제 이 아래 배열에 추가하면 자동으로 DB에 항목이 추가된다!
    kwFields: [ // 키워드가 적용된 필드의 배열
      { field: 'courtName', caption: '관할' },
      { field: 'fDate', caption: '기일' },
      { field: 'dDate', caption: '기한' },
      { field: 'caseName', caption: '사건명' },
      // { field: 'clType', caption: '의뢰인유형' },
      // { field: 'opType', caption: '상대방유형' },
      { field: 'dangType', caption: '당사자유형' }, // !![2021.4.8] 당사자유형으로 통합
      { field: 'jepanbu', caption: '재판부' }
    ],
    selectedField: 0, // 리스트그룹의 선택된 필드
    selectedKeywords: [ // 선택된 메뉴의 키워드 배열
      { text: '', isChecked: false }
    ],
    dndEnabled: true, // dnd 사용여부
    dragging: false,
    allChecked: false, // 전체 체크된 상태
    isSomeChecked: false, // 뭐든 체크된게 있다
    orderChanged: false, // 순서변경여부
    keywordLoading: false // 로딩
  }),

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 로딩시 첫번째 필드의 키워드만 패칭
    this.selectKeyword(this.kwFields[0].field)
  },

  watch: {
    // !! 선택된 필드의 키워드를 감시하자
    selectedKeywords: {
      handler (curr, prev) {
        if (curr.length > 0) { // curr 는 기본적으로 배열임
          // 체크한 키워드갯수
          const checkedLen = curr.filter(c => c.isChecked === true).length
          // 체크된 갯수와 배열의 갯수가 같으면 전체 체크 && 뭐든 체크된게 있는것
          if (checkedLen === curr.length) {
            this.isSomeChecked = true
            this.allChecked = true
          } else if (checkedLen > 0) {
            this.isSomeChecked = true
            this.allChecked = false
          } else {
            this.isSomeChecked = false
            this.allChecked = false
          }
        }
      },
      deep: true
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async init () {
      try {
        this.dndEnabled = true // 드래그 앤 드랍 사용가능하게
        this.orderChanged = false // 순서변경여부 초기화
        this.dragging = false
        this.allChecked = false
        this.isSomeChecked = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택 필드에 새로운 키워드를 등록한다.
    async addKeyword (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addKeywordPop.showMenu) {
          await this.$refs.addKeywordPop.show(evt) // 일단 열고

          // 전송할 객체
          const sendObject = {
            field: '',
            caption: '', // !![2021.4.8 추가]
            keywords: [],
            index: -1,
            edit: ''
          }

          sendObject.field = this.kwFields[this.selectedField].field // 선택한 필드명
          sendObject.caption = this.kwFields[this.selectedField].caption // 선택한 필드의 캡션
          sendObject.keywords = this.selectedKeywords.map(c => c.text) // 선택 필드의 키워드 배열

          // !! 수정인 경우
          if (evt.target.dataset && evt.target.dataset.index && evt.target.dataset.edit) {
            sendObject.index = evt.target.dataset.index
            sendObject.edit = evt.target.dataset.edit
          }

          // 나머지 인자를 보낸다
          const result = await this.$refs.addKeywordPop.setData(sendObject)
          if (result) {
            // refresh
            await this.selectKeyword(sendObject.field)
            this.init() // 초기화 하고
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택 필드의 키워드를 조작해서 this.selectedKeywords 배열에 채워넣는 역할이다.
    async selectKeyword (field) {
      try {
        this.keywordLoading = true

        // console.log(field)
        const { data } = await this.$axios.post('lawork/lwc/getKeywords', { fields: [ field ] })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // !! 101 ~ 300 마이크로초 사이의 딜레이를 두고 실행
        sleep(300 - Math.floor(Math.random() * 200)).then(() => {
          this.keywordLoading = false

          this.selectedKeywords = []
          if (data.keyword && data.keyword[field].length > 0) {
            data.keyword[field].map(kw => {
              this.selectedKeywords.push({ text: kw, isChecked: false })
            })
          }
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 리스트 순서를 변경하도록 하는 메서드
    async orderChange (e) {
      try {
        if (this.orderChanged) { // 순서가 변경된 경우만..
          this.orderChanged = false // 순서변경 여부 초기화

          // !![2021.4.8 수정]
          let sendKeyword = { field: '', caption: '', keywords: [] }
          const field = this.kwFields[this.selectedField].field // 현재 선택한 필드명
          sendKeyword.field = field
          sendKeyword.caption = this.kwFields[this.selectedField].caption // 현재 선택한 캡션
          sendKeyword.keywords = this.selectedKeywords.map(c => c.text) // text 항목만 뽑아서 배열로 재구성

          // 변경된 순서를 DB에 저장
          const { data } = await this.$axios.post(`lawork/lwc/setKeyword`, sendKeyword)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // refresh
          await this.selectKeyword(field)
          this.init() // 초기화 하고
        } else {
          throw new Error('키워드 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 리스트 체크박스 전체 선택/해제
    allCheck () {
      if (this.selectedKeywords.length > 0) {
        if (!this.allChecked) {
          this.selectedKeywords.forEach(c => { c.isChecked = true })
          this.allChecked = true
          this.isSomeChecked = true
        } else {
          this.selectedKeywords.forEach(c => { c.isChecked = false })
          this.allChecked = false
          this.isSomeChecked = false
        }
      }
    },
    // 구분: 아이콘을 클릭해 1개의 키워드만 삭제
    async rmOneKeyword (index) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        const msg = `정말 삭제하시겠습니까?<br>이미 등록된 정보에는 영향을 주지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !![2021.4.8 수정]
          let sendKeyword = { field: '', caption: '', keywords: [] }
          const field = this.kwFields[this.selectedField].field // 현재 선택한 필드명
          this.selectedKeywords.splice(index, 1) // 제거
          sendKeyword.field = field
          sendKeyword.caption = this.kwFields[this.selectedField].caption // 현재 선택한 캡션
          sendKeyword.keywords = this.selectedKeywords.map(c => c.text) // text 항목만 뽑아서 배열로 재구성

          // 변경된 순서를 DB에 저장
          const { data } = await this.$axios.post(`lawork/lwc/setKeyword`, sendKeyword)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // refresh
          await this.selectKeyword(field)
          this.init() // 초기화 하고
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 체크된 키워드를 삭제하는 함수
    async rmKeywords () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        const msg = `정말 삭제하시겠습니까?<br>이미 등록된 정보에는 영향을 주지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! 체크된게 있는 경우만..
          if (this.isSomeChecked) {
            // !![2021.4.8 수정]
            let sendKeyword = { field: '', caption: '', keywords: [] }
            const field = this.kwFields[this.selectedField].field // 현재 선택한 필드명

            sendKeyword.field = field
            sendKeyword.caption = this.kwFields[this.selectedField].caption // 현재 선택한 캡션

            // 주의: 체크된 것을 삭제하는 것이므로, 반대로 체크되지 않은 리스트의 텍스트만 모아서 업데이트한다.
            sendKeyword.keywords = this.selectedKeywords.filter(c => c.isChecked === false).map(c => c.text)

            // 변경된 순서를 DB에 저장
            const { data } = await this.$axios.post(`lawork/lwc/setKeyword`, sendKeyword)
            if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
            // refresh
            await this.selectKeyword(field)
            this.init() // 초기화 하고
          } else {
            throw new Error('삭제할 키워드를 선택해 주세요')
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
